<template>
  <div v-if="current_clinic">
    <ClinicPageNotifications
      v-if="show_notifications && current_clinic && $route.name == 'clinic_page'"
      :clinic_id="clinic_id"
      :num_users="my_users.length + other_users.length"
      :is_free="is_free"
      :role="clinic_role"
      :no_trial_end_alert="no_trial_end_alert"
      @billing_click="billing_page"
    />

    <v-app-bar dark flat app class="page_toolbar">
      <template>
        <v-toolbar-title style="cursor: pointer" @click="$route.path != '/app/clinic/' + clinic_id && $router.push('/app/clinic/' + clinic_id)">
          <v-icon left>business</v-icon>
          <span>{{ current_clinic.title }}</span>
        </v-toolbar-title>
      </template>
      <v-spacer />
      <v-toolbar-items>
        <v-btn v-if="permission_edit_clinic" class="clinic_page_menu_button clinic_page_edit_info_button" small text @click="clinic_edit_dialog = true">
          <v-icon small left>edit</v-icon>
          {{ $t("clinic_page.edit_info") }}
        </v-btn>

        <v-btn v-if="permission_invite_user" class="clinic_page_menu_button clinic_page_archived_users_button" small text @click="view_archived_users">
          <v-icon small left>mdi-archive</v-icon>
          {{ $t("common.archived_users") }}
        </v-btn>

        <v-btn v-if="permission_view_staff" class="clinic_page_menu_button clinic_page_staff_button" small text @click="staff_edit_dialog = true">
          <v-icon small left>fa-user-edit</v-icon>
          <span v-if="is_educational">Teachers and Students</span>
          <span v-else>{{ $t("common.staff") }}</span>
        </v-btn>

        <v-btn v-if="permission_view_billing" class="clinic_page_menu_button" small text :disabled="$route.name == 'clinic_billing'" @click="billing_page">
          <v-icon small left>fas fa-money-check-alt</v-icon>
          {{ $t("clinic_page.billing") }}
        </v-btn>
      </v-toolbar-items>
      <template v-if="clinic_role == 'user' && $vuetify.breakpoint.name != 'xs'" v-slot:extension>
        <v-layout justify-space-between>
          <div>
            <v-btn v-if="current_clinic.website" text :href="website_link" target="_blank">
              <v-icon left>link</v-icon>
              {{ website_view }}
            </v-btn>
            <v-btn v-if="current_clinic.phone" text :href="telephone_link">
              <v-icon left>phone</v-icon>
              {{ current_clinic.phone }}
            </v-btn>
            <v-btn v-if="physical_address" text :href="address_link" target="_blank">
              <v-icon left>location_on</v-icon>
              {{ physical_address }}
            </v-btn>
          </div>
        </v-layout>
      </template>
    </v-app-bar>

    <!-- Archived Users List -->
    <v-dialog v-model="archived_users_dialog" max-width="1000" scrollable>
      <v-card v-if="archived_users_dialog">
        <v-toolbar>
          <v-btn icon @click="archived_users_dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t("common.archived_users") }}</v-toolbar-title>
          <v-spacer />
        </v-toolbar>
        <v-card-text>
          <v-banner two-line style="margin-top: 20px">
            <p>
              {{ $t("clinic_page.archived_user_first_text") }}
              <v-icon x-small>fa fa-chart-bar</v-icon>
              &nbsp;{{ $t("clinic_page.for_archived_user") }}
              <v-icon small>unarchive</v-icon>
              {{ $t("clinic_page.icon") }}
            </p>
          </v-banner>

          <v-text-field
            v-if="archived_users.length > 1"
            v-model="archived_user_search"
            append-icon="mdi-magnify"
            :label="$t('common.search')"
            single-line
            hide-details
            style="padding: 20px"
          ></v-text-field>

          <div v-if="archived_users && archived_users.length != 0">
            <v-list two-line subheader>
              <div v-for="(item, index) in archived_users" :key="index">
                <v-list-item v-if="filter_user_line(item, archived_user_search)">
                  <v-list-item-avatar>
                    <v-icon class="grey lighten-1 white--text">account_circle</v-icon>
                  </v-list-item-avatar>

                  <!-- Name and email -->
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name_given }} {{ item.name_family[0] }}</v-list-item-title>
                    <v-list-item-subtitle style="white-space: pre-line; max-height: 40px">{{ item.email }}</v-list-item-subtitle>
                  </v-list-item-content>

                  <!-- Archived Since -->

                  <!-- Actions -->
                  <v-list-item-action>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn small outlined fab color="blue" class="$vuetify.rtl ? 'mr-2': 'ml-2'" @click="archive_report_dialog_open(item.id)" v-on="on">
                          <v-icon>fa-chart-bar</v-icon>
                        </v-btn>
                      </template>
                      {{ $t("clinic_page.view_archived_report") }}
                    </v-tooltip>
                  </v-list-item-action>

                  <v-list-item-action>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn small outlined fab color="green" @click="unarchive_user(item)" v-on="on">
                          <v-icon>unarchive</v-icon>
                        </v-btn>
                      </template>
                      {{ $t("clinic_page.restore") }} {{ item.name_given }}
                      {{ item.name_family[0] }}
                    </v-tooltip>
                  </v-list-item-action>
                </v-list-item>

                <v-dialog v-model="archive_report_dialogs[item.id]" scrollable max-width="1400">
                  <RegimenReport
                    v-if="archive_report_dialogs[item.id] && exercises && video_exercises"
                    ref="archived_regimen_report"
                    :invite_id="item.invite_id"
                    :clinic_id="current_clinic.id"
                    :exercises="exercises"
                    :video_exercises="video_exercises"
                    @close="archive_report_dialogs[item.id] = false"
                  />
                </v-dialog>
              </div>
            </v-list>
          </div>
          <v-banner v-else style="margin-top: 20px; font-style: italic">
            {{ $t("clinic_page.no_archived_users_first") }}
            <v-icon small>archive</v-icon>
            {{ $t("clinic_page.no_archived_users_second") }}
          </v-banner>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Edit clinic dialog -->
    <v-dialog v-model="clinic_edit_dialog" max-width="1400" scrollable>
      <ClinicEdit
        v-if="clinic_edit_dialog"
        :clinic="current_clinic"
        :admin_users="clinic_admin"
        @close-clinic-edit="clinic_edit_dialog = false"
        @clinic-updated="load_clinic_info"
      />
    </v-dialog>

    <!-- Staff and Admin dialog -->
    <v-dialog v-model="staff_edit_dialog" max-width="1400">
      <v-card v-if="staff_edit_dialog">
        <v-toolbar>
          <v-btn icon @click="staff_edit_dialog = false">
            <v-icon>close</v-icon>
          </v-btn>
          <v-toolbar-title>
            <span v-if="is_educational">Teachers and Students</span>
            <span v-else>{{ $t("common.staff") }}</span>
          </v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn v-if="permission_invite_staff" small color="primary" @click="create_staff_dialog = true">
              <span v-if="is_educational">Invite New Teacher or Student</span>
              <span v-else>{{ $t("clinic_page.invite_staff") }}</span>
            </v-btn>
            <v-dialog v-model="create_staff_dialog" max-width="1000">
              <InviteEdit
                v-if="create_staff_dialog && staff_invite"
                resend_on_update
                :invite="staff_invite"
                :clinic="current_clinic"
                :current_user="current_user"
                :show_hipaa_notice="is_hipaa"
                :is_educational="is_educational"
                allow_user_role_edit
                @close-invite-edit="create_staff_dialog = false"
                @invite-created="load_clinic"
                @invite-updated="load_clinic"
              />
            </v-dialog>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <v-banner v-if="!is_educational" two-line style="margin-top: 20px">
            <p>{{ $t("clinic_page.staff_accounts_text") }}</p>
          </v-banner>
          <v-list v-if="clinic_staff && clinic_staff.length != 0">
            <v-list-item v-for="(item, index) in clinic_staff" :key="index">
              <v-list-item-avatar class="invite_line_avatar_container">
                <v-icon class="grey lighten-1 white--text">account_circle</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="ml-0"
                  >{{ item.name_prefix }} {{ item.name_given }}
                  {{ item.name_family }}
                  {{ item.name_credentials }}</v-list-item-title
                >
                <v-list-item-subtitle>
                  <span v-if="is_educational">
                    <span v-if="item.user_role == 'admin'">Teacher</span>
                    <span v-else-if="item.user_role == 'staff'">Student</span>
                    <span v-else>{{ item.user_role }}</span>
                  </span>
                  <span v-else>{{ item.user_role }}</span>
                </v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-content>{{ item.email }}</v-list-item-content>

              <v-list-item-action v-if="current_user.is_admin">
                <v-btn small outlined fab color="orange" :class="$vuetify.rtl ? 'ml-2' : 'mr-2'" @click="masquerade_as_user(item)">
                  <v-icon>pageview</v-icon>
                </v-btn>
              </v-list-item-action>

              <v-list-item-action>
                <v-btn small outlined fab color="green" :class="$vuetify.rtl ? 'ml-2' : 'mr-2'" @click="open_edit_staff_role(item.id)">
                  <v-icon>edit</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-list-item-action>
                <v-btn
                  v-if="permission_invite_staff && item.user_id != current_user.id"
                  small
                  outlined
                  fab
                  color="red"
                  @click="delete_invite(item.invite_id, 'staff')"
                >
                  <v-icon>delete</v-icon>
                </v-btn>
              </v-list-item-action>
              <v-dialog v-model="edit_staff_role[item.id]" max-width="1000">
                <InviteRoleEdit
                  :invite_id="edit_staff_role[item.id] && item.invite_id"
                  :current_role="item.user_role"
                  :current_user="current_user"
                  :is_educational="is_educational"
                  :nopatients="is_nopatients"
                  @close="close_edit_staff_role(item.id)"
                  @invite-role-updated="load_clinic"
                />
              </v-dialog>
            </v-list-item>
            <v-divider inset />
          </v-list>

          <!-- Unaccepted Staff Invites -->
          <div v-if="exercises && video_exercises && unaccepted_staff_invites && unaccepted_staff_invites.length != 0" style="width: 100%">
            <div v-for="(item, index) in unaccepted_staff_invites" :key="index">
              <ClinicPageInviteLine
                hide_assigned
                :invite="item"
                :current_user="current_user"
                :clinic="current_clinic"
                :exercises="exercises"
                :video_exercises="video_exercises"
                :clinic_staff="clinic_staff"
                :owner="ownership[item.id]"
                :current_user_role="clinic_role"
                :grid_sets="grid_sets"
                :grid_schedules="grid_schedules"
                class="pl-4 pr-2 mr-n1 pt-0 pb-0"
                allow_user_role_edit
                @delete_invite="delete_invite(item.id)"
                @invite_updated="load_clinic"
              ></ClinicPageInviteLine>
            </div>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- No access to this clinic -->
    <div v-if="clinic_invite && clinic_invite.archived">
      <v-sheet elevation="5" class="pa-6">
        {{
          $t("clinic_page.invite_archived", {
            clinic_title: current_clinic.title,
          })
        }}
      </v-sheet>
      <SelectClinic />
    </div>

    <!-- User Regimen Or New User Subscription -->
    <div v-else-if="clinic_role == 'user'">
      <div v-if="needs_user_subscription">
        <UserNewSubscription v-if="current_clinic && current_user" :current_clinic="current_clinic" />
      </div>

      <Regimen
        v-else-if="exercises && video_exercises && current_clinic && current_user && current_progress_reports && !show_educational_select_role"
        :invite_id="clinic_invite.id"
        :clinic="current_clinic"
        :user="current_user"
        :exercises="exercises"
        :video_exercises="video_exercises"
      />
    </div>

    <!-- Billing -->
    <!-- TODO: This is a super weird way to do routing, fix it eventually -->
    <div v-else-if="$route.name == 'clinic_billing'">
      <v-toolbar grey>
        <v-btn icon @click="$router.go(-1)">
          <v-icon>keyboard_backspace</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("clinic_page.billing") }}</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <ClinicBilling
        ref="clinic_billing"
        :current_user_role="clinic_role"
        :clinic="current_clinic"
        :clinic_admins="clinic_admin"
        @clinic-updated="load_clinic_info"
      />
    </div>

    <!-- Grids -->
    <!-- TODO: This is a super weird way to do routing, fix it eventually -->
    <div v-else-if="$route.name == 'clinic_grids'">
      <v-toolbar grey>
        <v-btn icon @click="$router.go(-1)">
          <v-icon>keyboard_backspace</v-icon>
        </v-btn>
        <v-toolbar-title>{{ $t("clinic_page.grids") }}</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <ClinicGrids
        ref="clinic_grids"
        :current_user="current_user"
        :current_user_role="clinic_role"
        :clinic="current_clinic"
        :exercises="exercises"
        :video_exercises="video_exercises"
        :grid_sets="grid_sets"
        :grid_schedules="grid_schedules"
        :show_id="current_user.is_admin"
        @grid-set-created="load_grids"
        @grid-set-updated="load_grids"
        @grid-set-deleted="load_grids"
        @grid-schedule-created="load_grids"
        @grid-schedule-updated="load_grids"
        @grid-schedule-deleted="load_grids"
      />
    </div>

    <!-- Educational - No Patients -->
    <div v-else-if="is_nopatients">
      <ExerciseDemoList :exercises="exercises" :current_user="current_user" top_distance="175" />
    </div>

    <!-- User list section -->
    <div v-else>
      <!-- Users toolbar -->
      <v-app-bar flat app style="margin-top: 64px; border-bottom: 1px solid rgba(0, 0, 0, 0.2)">
        <v-text-field
          v-if="clinic_users.length + unaccepted_user_invites.length > 1"
          v-model="user_search"
          append-icon="mdi-magnify"
          :label="$t('common.search')"
          single-line
          hide-details
          outlined
          dense
          style="max-width: 500px"
        ></v-text-field>
        <v-spacer />
        <v-btn v-if="permission_invite_user" class="clinic_page_invite_patient_button" color="primary" @click="create_invite_dialog = true">
          {{ $t("clinic_page.invite_patient") }}
        </v-btn>
        <v-dialog v-model="create_invite_dialog" max-width="1000">
          <InviteEdit
            v-if="create_invite_dialog && default_invite"
            resend_on_update
            :invite="default_invite"
            :clinic="current_clinic"
            :current_user="current_user"
            :check_trial_expired="true"
            :current_user_role="clinic_role"
            @billing_click="billing_page"
            @close-invite-edit="create_invite_dialog = false"
            @invite-created="load_clinic"
            @invite-updated="load_clinic"
          />
        </v-dialog>
      </v-app-bar>

      <!-- My Users List -->
      <div
        v-if="
          exercises &&
          video_exercises &&
          !show_educational_select_role &&
          ((my_users && my_users_filtered.length != 0) || (my_user_invites && my_user_invites_filtered.length != 0))
        "
      >
        <h4 :class="$vuetify.rtl ? 'headline mb-1 mr-2 mt-6' : 'headline mb-1 ml-2 mt-6'">
          {{
            $t("common.assigned_to", {
              name_given: current_user.name_given,
              name_family: current_user.name_family,
            })
          }}
        </h4>

        <div style="width: 100%">
          <div v-for="(item, index) in my_users_filtered" :key="index">
            <v-lazy :options="{ threshold: 0.1 }" min-height="55">
              <ClinicPageUserLine
                :user="item"
                :subscription="item.subscription"
                :current_user="current_user"
                :clinic="current_clinic"
                :exercises="exercises"
                :video_exercises="video_exercises"
                :clinic_staff="clinic_staff"
                :owner="ownership[item.id]"
                :activity_summary="activity_summary[item.id]"
                :latest_activity="latest_activity[item.invite_id]"
                :show_days_since="latest_activity_loaded"
                :grid_sets="grid_sets"
                :grid_schedules="grid_schedules"
                :dense="dense"
                :x_dense="x_dense"
                :show_assignment="!x_dense"
                style="border-bottom: 1px solid lightgrey"
                @archive_user="archive_user(item)"
                @masquerade_as_user="masquerade_as_user(item)"
                @regimen_created="load_clinic_users(assign_ownership)"
                @regimen_updated="load_clinic_users(assign_ownership)"
                @user_updated="load_clinic"
                @invite_updated="load_clinic_users()"
              ></ClinicPageUserLine>
            </v-lazy>
          </div>
        </div>

        <!-- My User Invites -->
        <div style="width: 100%">
          <div v-for="(item, index) in my_user_invites_filtered" :key="index">
            <v-lazy :options="{ threshold: 0.1 }" min-height="55">
              <ClinicPageInviteLine
                :invite="item"
                :current_user="current_user"
                :clinic="current_clinic"
                :exercises="exercises"
                :video_exercises="video_exercises"
                :clinic_staff="clinic_staff"
                :owner="ownership[item.id]"
                :current_user_role="clinic_role"
                :grid_sets="grid_sets"
                :grid_schedules="grid_schedules"
                :dense="dense"
                :x_dense="x_dense"
                :show_assignment="!x_dense"
                style="border-bottom: 1px solid lightgrey"
                @delete_invite="delete_invite(item.id)"
                @invite_updated="load_clinic"
                @regimen_created="load_clinic_users(assign_ownership)"
                @regimen_updated="load_clinic_users(assign_ownership)"
              ></ClinicPageInviteLine>
            </v-lazy>
          </div>
        </div>
      </div>

      <!-- Other Users List -->
      <div
        v-if="
          exercises &&
          video_exercises &&
          !show_educational_select_role &&
          ((other_users && other_users_filtered.length != 0) || (other_user_invites && other_user_invites_filtered.length != 0))
        "
      >
        <h4 :class="$vuetify.rtl ? 'headline mb-1 mr-2 mt-12' : 'headline mb-1 ml-2 mt-12'">
          {{ $t("clinic_page.assigned_to_others") }}
        </h4>

        <div style="width: 100%">
          <div v-for="(item, index) in other_users_filtered" :key="index">
            <v-lazy :options="{ threshold: 0.1 }" min-height="55">
              <ClinicPageUserLine
                :user="item"
                :subscription="item.subscription"
                :current_user="current_user"
                :clinic="current_clinic"
                :exercises="exercises"
                :video_exercises="video_exercises"
                :clinic_staff="clinic_staff"
                :owner="ownership[item.id]"
                :activity_summary="activity_summary[item.id]"
                :latest_activity="latest_activity[item.invite_id]"
                :show_days_since="latest_activity_loaded"
                :grid_sets="grid_sets"
                :grid_schedules="grid_schedules"
                :dense="dense"
                :x_dense="x_dense"
                :show_assignment="!x_dense"
                style="border-bottom: 1px solid lightgrey"
                @archive_user="archive_user(item)"
                @masquerade_as_user="masquerade_as_user(item)"
                @regimen_created="load_clinic_users(assign_ownership)"
                @regimen_updated="load_clinic_users(assign_ownership)"
                @user_updated="load_clinic"
                @invite_updated="load_clinic_users()"
              ></ClinicPageUserLine>
              <v-divider v-if="filter_user_line(item, user_search)" inset />
            </v-lazy>
          </div>
        </div>

        <!-- Other User Invites -->
        <div style="width: 100%">
          <div v-for="(item, index) in other_user_invites_filtered" :key="index">
            <v-lazy :options="{ threshold: 0.1 }" min-height="55">
              <ClinicPageInviteLine
                :invite="item"
                :current_user="current_user"
                :clinic="current_clinic"
                :exercises="exercises"
                :video_exercises="video_exercises"
                :clinic_staff="clinic_staff"
                :owner="ownership[item.id]"
                :current_user_role="clinic_role"
                :grid_sets="grid_sets"
                :grid_schedules="grid_schedules"
                :dense="dense"
                :x_dense="x_dense"
                :show_assignment="!x_dense"
                style="border-bottom: 1px solid lightgrey"
                @delete_invite="delete_invite(item.id)"
                @invite_updated="load_clinic"
                @regimen_created="load_clinic_users(assign_owƒnership)"
                @regimen_updated="load_clinic_users(assign_ownership)"
              ></ClinicPageInviteLine>
            </v-lazy>
          </div>
        </div>
      </div>

      <!-- No Results -->

      <NoResults
        v-if="
          user_search &&
          my_users_filtered.length == 0 &&
          other_users_filtered.length == 0 &&
          my_user_invites_filtered.length == 0 &&
          other_user_invites_filtered.length == 0
        "
        :query="user_search"
        class="mt-12"
      />

      <!-- Some Space at the bottom for the helpscout overlay -->
      <div style="height: 40px" />

      <!-- Welcome box -->
      <div
        v-if="
          loaded &&
          my_user_invites &&
          other_user_invites &&
          my_users &&
          other_users &&
          !show_educational_select_role &&
          my_user_invites.length + other_user_invites.length + my_users.length + other_users.length < 5 &&
          (!current_clinic.stripe_subscription_id || my_user_invites.length + other_user_invites.length + my_users.length + other_users.length == 0)
        "
        style="margin-top: 30px"
      >
        <WelcomeVideo>
          <p
            v-if="
              my_user_invites &&
              my_user_invites.length == 0 &&
              other_user_invites &&
              other_user_invites.length == 0 &&
              my_users &&
              my_users.length == 0 &&
              other_users &&
              other_users.length == 0
            "
          >
            {{ $t("clinic_page.welcome_first_text") }}
          </p>
        </WelcomeVideo>
      </div>
    </div>

    <!-- Educational User select Role -->
    <div v-if="show_educational_select_role">
      <v-container>
        <v-layout align-center justify-center>
          <v-flex xs8 m6>
            <v-card>
              <v-toolbar dark color="primary">
                <v-toolbar-title>{{ $t("clinic_page.select_role") }}</v-toolbar-title>
                <v-spacer />
              </v-toolbar>
              <v-card-text>
                <v-list two-line subheader>
                  <v-list-item @click="educational_select_role_patient()">
                    <v-list-item-content>{{ $t("clinic_page.patient") }}</v-list-item-content>
                    <v-list-item-content>{{ $t("clinic_page.patient_desc") }}</v-list-item-content>
                  </v-list-item>
                  <v-divider />
                  <v-list-item @click="educational_select_role_optometrist()">
                    <v-list-item-content>{{ $t("clinic_page.optometrist") }}</v-list-item-content>
                    <v-list-item-content>{{ $t("clinic_page.optometrist_desc") }}</v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";

import InviteRoleEdit from "./InviteRoleEdit";
import ClinicEdit from "./ClinicEdit";
import ClinicPageInviteLine from "./ClinicPageInviteLine";
import ClinicPageUserLine from "./ClinicPageUserLine";
import ClinicPageNotifications from "./ClinicPageNotifications";
import ClinicBilling from "./ClinicBilling";
import ClinicGrids from "./ClinicGrids";
import InviteEdit from "./InviteEdit";
import Regimen from "./Regimen";
import RegimenReport from "./RegimenReport";
import ExerciseDemoList from "./ExerciseDemoList";
import WelcomeVideo from "./WelcomeVideo";
import UserNewSubscription from "./UserNewSubscription";
import SelectClinic from "../../shared/components/SelectClinic";
import NoResults from "./NoResults";
import { mapState } from "vuex";
import { logout_helpscout } from "../lib/helpscout";

export default {
  components: {
    ClinicEdit,
    ClinicPageInviteLine,
    ClinicPageUserLine,
    InviteEdit,
    InviteRoleEdit,
    Regimen,
    RegimenReport,
    ClinicBilling,
    ClinicPageNotifications,
    ExerciseDemoList,
    ClinicGrids,
    WelcomeVideo,
    UserNewSubscription,
    SelectClinic,
    NoResults,
  },
  props: {
    clinic_id: {
      type: String,
      required: true,
    },
    current_user: {
      type: Object,
      required: true,
    },
    user_invites: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loaded: false,
      activity_summary: {},
      latest_activity: {},
      latest_activity_loaded: false,
      all_users: [],
      archive_report_dialogs: {},
      archived_users: [],
      archived_users_dialog: false,
      clinic_admin: [],
      clinic_edit_dialog: false,
      clinic_staff: [],
      clinic_users: [],
      create_invite_dialog: false,
      create_staff_dialog: false,
      my_user_invites: [],
      my_users: [],
      other_users: [],
      other_user_invites: [],
      ownership: {},
      rows_per_page: [-1],
      edit_staff_role: {},
      staff_edit_dialog: false,
      unaccepted_staff_invites: [],
      unaccepted_user_invites: [],
      clinic_notifications: null,
      user_search: "",
      archived_user_search: "",
      show_notifications: false,
      selected_educational_role: "",
    };
  },
  computed: {
    ...mapState([
      "current_clinic",
      "exercises",
      "video_exercises",
      "grid_sets",
      "grid_schedules",
      "user_subscriptions",
      "app_config",
      "user_regimens",
      "current_progress_reports",
      "user_clinics",
    ]),
    my_users_filtered() {
      return this.my_users.filter((user) => this.filter_user_line(user, this.user_search));
    },
    other_users_filtered() {
      return this.other_users.filter((user) => this.filter_user_line(user, this.user_search));
    },
    my_user_invites_filtered() {
      return this.my_user_invites.filter((invite) => this.filter_invite_line(invite));
    },
    other_user_invites_filtered() {
      return this.other_user_invites.filter((invite) => this.filter_invite_line(invite));
    },
    dense() {
      return (
        (this.$root.$children[0].drawer_mini && this.$vuetify.breakpoint.width < 1500) ||
        (!this.$root.$children[0].drawer_mini && this.$vuetify.breakpoint.width < 1590)
      );
    },
    x_dense() {
      return (!this.$root.$children[0].drawer_mini && this.$vuetify.breakpoint.width < 1280) || this.$vuetify.breakpoint.width < 1080;
    },
    is_ipad_sized() {
      let matched = window.matchMedia("(min-device-width: 740px) and (max-device-width: 1080px) and (orientation: landscape)");
      return matched.matches;
    },
    clinic_invite() {
      if (this.user_invites) {
        for (var i in this.user_invites) {
          let invite = this.user_invites[i];
          if (invite.clinic_id == this.clinic_id) {
            if (this.selected_educational_role) {
              if (invite.user_role == this.selected_educational_role) {
                return invite;
              } else {
                continue;
              }
            }
            return invite;
          }
        }
      }
      return null;
    },
    clinic_role() {
      if (this.current_user && this.current_user.is_admin) {
        return "admin";
      }
      if (this.clinic_invite) {
        return this.clinic_invite.user_role;
      }
      return "";
    },
    show_educational_select_role() {
      if (this.current_clinic && this.current_clinic.has_tag("educational")) {
        if (this.selected_educational_role == "") {
          let roles = [];
          if (this.user_invites) {
            for (var i in this.user_invites) {
              let invite = this.user_invites[i];
              if (invite.clinic_id == this.clinic_id) {
                roles.push(invite.user_role);
              }
            }
          }

          if (roles.length > 1) {
            return true;
          }
        }
      }
      return false;
    },
    needs_user_subscription() {
      if (
        !this.clinic_invite ||
        this.clinic_invite.billing_strategy == "clinic" ||
        this.clinic_invite.billing_strategy == "free" ||
        (!this.clinic_invite.billing_strategy && this.current_clinic.billing_strategy == "clinic") ||
        (!this.clinic_invite.billing_strategy && this.current_clinic.billing_strategy == "free") ||
        this.current_clinic.has_tag("free")
      ) {
        return false;
      }

      // Check if the clinic is trialing
      if (this.current_clinic.trial_end) {
        let current_date = new Date();
        let trial_end = new Date(this.current_clinic.trial_end);

        // 1 day grace period
        trial_end.setDate(trial_end.getDate() + 1);

        if (current_date < trial_end) {
          return false;
        }
      }

      // Check if we have a subscription
      for (let sub of this.user_subscriptions) {
        if (
          sub.invite_id == this.clinic_invite.id &&
          sub.stripe_subscription_status != "canceled" &&
          sub.stripe_subscription_status != "incomplete_expired" &&
          sub.stripe_subscription_status != "incomplete"
        ) {
          return false;
        }
      }

      // Check for subscriptions that are not defined through stripe, but rather defined internally
      // using an end-date or a trial-end-date
      for (let sub of this.user_subscriptions) {
        if (!sub.stripe_subscription_id) {
          let current_date = new Date();
          // Check the trial_end_date, which is in ISO format
          if (sub.trial_end_date) {
            let trial_end = new Date(sub.trial_end_date);

            // 1 day grace period
            trial_end.setDate(trial_end.getDate() + 1);

            if (current_date < trial_end) {
              return false;
            }
          }

          // Check the end_date, which is in ISO format
          if (sub.end_date) {
            let end_date = new Date(sub.end_date);

            // 1 day grace period
            end_date.setDate(end_date.getDate() + 1);

            if (current_date < end_date) {
              return false;
            }
          }
        }
      }

      // If we are not a "subscription_first" app and there is no regimen assigned,
      // skip the need for a subscription in order to flow through to the "Nothing Assigned" UI (in the Regimen component)
      if (!this.app_config.subscription_first) {
        let has_regimen = false;
        for (let regimen of this.user_regimens) {
          if (regimen.invite_id == this.clinic_invite.id) {
            has_regimen = true;
            break;
          }
        }

        if (!has_regimen) {
          return false;
        }
      }

      return true;
    },
    is_educational() {
      return this.current_clinic && this.current_clinic.tag_value("educational") === true;
    },
    is_nopatients() {
      return this.current_clinic && this.current_clinic.tag_value("nopatients") === true;
    },
    is_free() {
      return this.current_clinic && this.current_clinic.tag_value("free") === true;
    },
    is_hipaa() {
      return this.current_clinic && this.current_clinic.tag_value("hipaa") === true;
    },
    no_trial_end_alert() {
      return this.current_clinic && this.current_clinic.tag_value("no_trial_end_alert") === true;
    },
    permission_view_staff() {
      return this.clinic_role == "admin";
    },
    permission_invite_user() {
      if (this.is_nopatients) {
        return false;
      }
      return this.clinic_role == "staff" || this.clinic_role == "admin";
    },
    permission_invite_staff() {
      return this.clinic_role == "admin";
    },
    permission_edit_clinic() {
      return this.clinic_role == "admin";
    },
    permission_view_billing() {
      return this.current_user.is_admin || this.clinic_role == "admin";
    },
    permission_view_grids() {
      return this.current_clinic && (this.clinic_role == "admin" || this.clinic_role == "staff");
    },
    telephone_link() {
      return "tel:" + this.current_clinic.phone;
    },
    address_link() {
      return "https://google.com/maps/place/" + this.physical_address;
    },
    physical_address() {
      let parts = [];

      if (this.current_clinic.physical_address_1) {
        parts.push(this.current_clinic.physical_address_1);
      }
      if (this.current_clinic.physical_address_2) {
        parts.push(this.current_clinic.physical_address_2);
      }
      if (this.current_clinic.physical_sub_locality) {
        parts.push(this.current_clinic.physical_sub_locality);
      }
      if (this.current_clinic.physical_locality) {
        parts.push(this.current_clinic.physical_locality);
      }
      if (this.current_clinic.physical_sub_district) {
        parts.push(this.current_clinic.physical_sub_district);
      }
      if (this.current_clinic.physical_district) {
        parts.push(this.current_clinic.physical_district);
      }
      return parts.join(", ");
    },
    website_view() {
      return this.current_clinic.website.replace(/(^\w+:|^)\/\//, "");
    },
    website_link() {
      if (this.current_clinic.website.startsWith("http://") || this.current_clinic.website.startsWith("https://")) {
        return this.current_clinic.website;
      } else {
        return "http://" + this.current_clinic.website;
      }
    },
    default_invite() {
      if (this.current_user) {
        return {
          user_id: null,
          owner_id: this.current_user.id,
          clinic_id: this.current_clinic.id,
          name: "",
          user_role: "user",
          email: "",
          email_message: "",
          billing_strategy: null,
        };
      }
      return null;
    },
    staff_invite() {
      if (this.current_user) {
        return {
          user_id: null,
          owner_id: this.current_user.id,
          clinic_id: this.current_clinic.id,
          name: "",
          user_role: "staff",
          email: "",
          email_message: "",
        };
      }
      return null;
    },
  },
  watch: {
    current_clinic() {
      // TODO: Weird way to do this. Fix.
      if (this.$route.path.endsWith("/billing")) {
        //this.$nextTick(() => {
        //this.$refs.clinic_billing.load_billing();
        //});
      }
    },
  },
  mounted() {
    this.load_clinic();
  },
  methods: {
    educational_select_role_patient() {
      this.selected_educational_role = "user";
      this.$store.commit("setCurrentInvite", this.clinic_invite);
      this.$store.commit("setCurrentClinicRole", this.clinic_role);
      this.$store.dispatch("loadProgressReports");
    },
    educational_select_role_optometrist() {
      if (this.user_invites) {
        for (let invite of this.user_invites) {
          if (invite.user_role != "user") {
            this.selected_educational_role = invite.user_role;
            this.$store.commit("setCurrentInvite", invite);
            this.$store.commit("setCurrentClinicRole", invite.user_role);

            this.load_grids();
            this.load_clinic_users(() => {
              this.load_unaccepted_invites(() => {
                this.assign_ownership();
                this.load_user_activity();
                this.load_user_latest_activity();
                this.show_notifications = true;
              });
            });
            break;
          }
        }
      }
    },
    filter_user_line(item, search) {
      if (!search.trim()) {
        return true;
      }

      search = search.trim().toLowerCase();

      let full_name = item.name_given + " " + item.name_family;

      if (item.name_given.toLowerCase().includes(search)) {
        return true;
      } else if (item.name_family.toLowerCase().includes(search)) {
        return true;
      } else if (item.email.toLowerCase().includes(search)) {
        return true;
      } else if (full_name.toLowerCase().includes(search)) {
        return true;
      } else {
        return false;
      }
    },
    filter_invite_line(item) {
      if (!this.user_search.trim()) {
        return true;
      }
      let search = this.user_search.trim().toLowerCase();

      let full_name = item.name + " " + item.name_family;

      if (item.name.toLowerCase().includes(search)) {
        return true;
      } else if (item.name_family.toLowerCase().includes(search)) {
        return true;
      } else if (item.email.toLowerCase().includes(search)) {
        return true;
      } else if (full_name.toLowerCase().includes(search)) {
        return true;
      } else {
        return false;
      }
    },
    billing_page() {
      this.$router.push("/app/clinic/" + this.clinic_id + "/billing");
      //Vue.nextTick(() => {
      //self.$refs.clinic_billing.load_billing();
      //});
    },
    grids_page() {
      this.$router.push("/app/clinic/" + this.clinic_id + "/grids");
    },
    load_grids() {
      this.$store.dispatch("loadGrids");
    },
    load_clinic_info(callback) {
      let clinic_id = this.clinic_id;
      this.$store.dispatch("loadCurrentClinic", { clinic_id, callback });

      // If we don't have a current_clinic, then it failed to load
      // Check to see if we should redirect the user to a canonical clinic
      if (!this.current_clinic || this.current_clinic.id != this.clinic_id) {
        if (this.user_clinics && this.user_clinics.length) {
          for (let clinic of this.user_clinics) {
            if (clinic.has_tag("duplicate_clinics")) {
              let dupes = clinic.tag_value("duplicate_clinics");
              for (let dupe of dupes) {
                if (dupe == this.clinic_id) {
                  // Redirect to the canonical clinic
                  window.location.href = "/app/clinic/" + clinic.id;
                  return;
                }
              }
            }
          }
        }
      }
    },
    load_clinic_users(callback) {
      // Load Clinic Info
      let self = this;
      // Load Clinic user info if admin or staff
      if (this.clinic_role == "admin" || this.clinic_role == "staff") {
        // Load users
        axios.get("/api/clinic/" + self.clinic_id + "/users").then(function (response) {
          self.loaded = true;
          if (response.data.status == "success") {
            let users = response.data.data;
            // Organize alphabetically
            users.sort(function (a, b) {
              if (a.name_family.toUpperCase() > b.name_family.toUpperCase()) {
                return 1;
              } else if (a.name_family.toUpperCase() < b.name_family.toUpperCase()) {
                return -1;
              } else {
                return 0;
              }
            });

            self.all_users = users;
            self.my_users = []; // Users assigned to me
            self.other_users = []; // Users assigned to not me
            self.clinic_users = []; // All non-staff users
            self.clinic_staff = []; // All staff users
            self.clinic_admin = []; // All admin users

            for (var i in self.all_users) {
              let user = self.all_users[i];
              if (user.user_role == "user") {
                self.clinic_users.push(user);
                // Ownership is primarily set by regmine and secondarily set by invite
                if (user.invite_owner == self.current_user.id) {
                  self.my_users.push(user);
                } else {
                  self.other_users.push(user);
                }
              }
              if (user.user_role == "staff" || user.user_role == "admin") {
                self.clinic_staff.push(user);
                Vue.set(self.edit_staff_role, user.id, false);
              }
              if (user.user_role == "admin") {
                self.clinic_admin.push(user);
              }
            }
            if (callback) {
              callback();
            }
          } else {
            messageBus.$emit("error", response.data.error_message);
          }
        });
      }
    },
    load_unaccepted_invites(callback) {
      // Load Unaccepted Invites
      let self = this;
      if (this.clinic_role == "admin" || this.clinic_role == "staff") {
        axios.get("/api/clinic/" + self.clinic_id + "/invites?accepted=false&archived=false").then(function (response) {
          if (response.data.status == "success") {
            self.unaccepted_user_invites = [];
            self.unaccepted_staff_invites = [];
            self.my_user_invites = [];
            self.other_user_invites = [];

            for (var i in response.data.data) {
              let invite = response.data.data[i];
              if (!invite.user_id) {
                if (invite.user_role == "user") {
                  self.unaccepted_user_invites.push(invite);

                  if (invite.owner_id == self.current_user.id) {
                    self.my_user_invites.push(invite);
                  } else {
                    self.other_user_invites.push(invite);
                  }
                }
                if (invite.user_role == "staff" || invite.user_role == "admin") {
                  self.unaccepted_staff_invites.push(invite);
                }
              }
            }
            if (callback) {
              callback();
            }
          } else {
            messageBus.$emit("error", response.data.error_message);
          }
        });
      }
    },
    load_user_activity: function (callback) {
      // Load Clinic user activity info if admin or staff
      let self = this;

      let d = new Date();
      let offset = d.getTimezoneOffset();
      axios.get("/api/clinic/" + self.clinic_id + "/activity_summary?offset=" + offset).then(function (response) {
        if (response.data.status == "success") {
          self.activity_summary = response.data.data;
          if (callback) {
            callback();
          }
        } else {
          messageBus.$emit("error", response.data.error_message);
        }
      });
    },
    load_user_latest_activity: function (callback) {
      // Load Clinic user latest-activity info if admin or staff
      let self = this;
      axios.get("/api/clinic/" + self.clinic_id + "/latest_activity").then(function (response) {
        if (response.data.status == "success") {
          self.latest_activity = response.data.data;
          self.latest_activity_loaded = true;
          if (callback) {
            callback();
          }
        } else {
          messageBus.$emit("error", response.data.error_message);
        }
      });
    },
    load_clinic: function () {
      let self = this;
      this.load_clinic_info(function () {
        // Clinic loaded OK, continue loading other data
        self.load_grids();
        self.load_clinic_users(function () {
          self.load_unaccepted_invites(function () {
            self.assign_ownership();
            self.load_user_activity();
            self.load_user_latest_activity();
            self.show_notifications = true;
          });
        });
      });
    },
    delete_invite(invite_id, type = "user") {
      let force = false;
      var invite_title;
      if (type == "user") {
        invite_title = this.$t("common.invite");
      }
      if (type == "staff") {
        invite_title = this.$t("common.staff");
        force = true;
      }

      if (!confirm(this.$t("common.delete") + " " + invite_title + "?")) {
        return;
      }

      let self = this;
      axios.delete("/api/invite/" + invite_id + "?force=" + force).then(function (response) {
        if (response.data.status == "success") {
          messageBus.$emit("success", invite_title + " " + self.$t("common.deleted"));
          if (type == "user") {
            self.load_unaccepted_invites(function () {
              self.assign_ownership();
            });
          }
          if (type == "staff") {
            self.load_clinic_users(function () {
              self.assign_ownership();
            });
          }
        } else {
          messageBus.$emit("error", response.data.error_message);
        }
      });
    },
    archive_user(user) {
      if (!confirm(this.$t("common.archive") + " " + user.name_given + " " + user.name_family[0] + "?")) {
        return;
      }

      // Load the invite, modify archive status, then repost it
      let self = this;
      axios.get("/api/invite/" + user.invite_id).then(function (response) {
        if (response.data.status == "success") {
          let invite = response.data.data;
          invite.archived = true;
          axios.put("/api/invite/" + user.invite_id, invite).then(function (response) {
            if (response.data.status == "success") {
              messageBus.$emit("success", self.$t("clinic_page.user_archived"));
              self.load_clinic_users(function () {
                self.assign_ownership();
              });
            } else {
              messageBus.$emit("error", response.data.error_message);
            }
          });
        } else {
          messageBus.$emit("error", response.data.error_message);
        }
      });
    },
    unarchive_user(user) {
      // Load the invite, modify archive status, then repost it
      let self = this;
      axios.get("/api/invite/" + user.invite_id).then(function (response) {
        if (response.data.status == "success") {
          let invite = response.data.data;
          invite.archived = false;
          invite.expiry_date = null;
          axios.put("/api/invite/" + user.invite_id, invite).then(function (response) {
            if (response.data.status == "success") {
              messageBus.$emit("success", self.$t("clinic_page.user_restored"));
              self.load_clinic_users(function () {
                self.assign_ownership();
                self.load_archived_users();
              });
            } else {
              messageBus.$emit("error", response.data.error_message);
            }
          });
        } else {
          messageBus.$emit("error", response.data.error_message);
        }
      });
    },
    view_archived_users() {
      this.archived_users_dialog = true;
      this.load_archived_users();
    },
    load_archived_users() {
      let self = this;
      axios.get("/api/clinic/" + self.current_clinic.id + "/users?archived=true").then(function (response) {
        if (response.data.status == "success") {
          self.archived_users = response.data.data;
          self.archive_report_dialogs = {};
          for (var i in self.archived_users) {
            let user = self.archived_users[i];
            if (user.user_role == "user") {
              Vue.set(self.archive_report_dialogs, user.id, false);
            }
          }
        } else {
          messageBus.$emit("error", response.data.error_message);
        }
      });
    },
    masquerade_as_user(user) {
      let masquerade = {
        clinic_id: this.clinic_id,
        user_id: user.id,
      };

      let self = this;
      axios.post("/api/user/masquerade", masquerade).then(function (response) {
        let resp = response.data;
        if (resp.status == "success") {
          logout_helpscout();
          window.location.reload();
        } else {
          if (resp.error_code == "CannotMasquerade") {
            messageBus.$emit("error", self.$t("clinic_page.cannot_masquerade_as_this_user"));
          } else {
            messageBus.$emit("api-error", resp);
          }
        }
      });
    },
    archive_report_dialog_open(user_id) {
      Vue.set(this.archive_report_dialogs, user_id, true);
    },
    archive(_user_id, _index) {
      // TODO: Implement archive
    },
    assign_ownership() {
      if (this.clinic_staff.length == 0) {
        return;
      }

      // uuid for user, invites, and regimens => staff
      this.ownership = {};

      // Create an index of staff
      var staff_index = {};
      for (var i in this.clinic_staff) {
        staff_index[this.clinic_staff[i].id] = this.clinic_staff[i];
      }

      // eslint-disable-next-line
      for (var i in this.clinic_users) {
        // It's owned by the invite owner.
        if (staff_index[this.clinic_users[i].invite_owner]) {
          let staff = staff_index[this.clinic_users[i].invite_owner];
          if (staff) {
            this.ownership[this.clinic_users[i].id] = {
              id: staff.id,
              assigned: true,
              name_given: staff.name_given,
              name_family: staff.name_family,
              name: staff.name_given + " " + staff.name_family,
            };
          }
        }
        if (!this.ownership[this.clinic_users[i].id]) {
          this.ownership[this.clinic_users[i].id] = {
            id: null,
            assigned: false,
            name: "Unassigned",
          };
        }
      }

      // eslint-disable-next-line
      for (var i in this.unaccepted_user_invites) {
        if (staff_index[this.unaccepted_user_invites[i].owner_id]) {
          let staff = staff_index[this.unaccepted_user_invites[i].owner_id];

          this.ownership[this.unaccepted_user_invites[i].id] = {
            id: staff.id,
            assigned: true,
            name: staff.name_given + " " + staff.name_family,
          };
        } else {
          this.ownership[this.unaccepted_user_invites[i].id] = {
            id: null,
            assigned: false,
            name: "Unassigned",
          };
        }
      }
    },

    open_edit_staff_role(id) {
      Vue.set(this.edit_staff_role, id, true);
    },
    close_edit_staff_role(id) {
      Vue.set(this.edit_staff_role, id, false);
    },
  },
};
</script>

<style>
.regimen_print_icon:hover {
  color: black;
}

.clinic_page_edit_info_button {
  float: right;
}
</style>
