<template>
  <div class="clinic_billing">
    <div v-if="current_user_role != 'admin'" style="margin-top: 200px">
      <v-layout justify-center>
        <v-flex md6>Permission denied - you must be an administrator to view billing</v-flex>
      </v-layout>
    </div>
    <div v-else-if="!loading && billing_info">
      <!-- Trial / Past-Due Status -->
      <v-layout
        v-if="!is_free && !is_yearly && (!billing_info.subscription_status || billing_info.subscription_status != 'active')"
        align-center
        justify-center
      >
        <v-sheet elevation="3" style="width: 1000px; padding: 50px; margin: 30px">
          <div
            v-if="!billing_info.subscription_status || billing_info.subscription_status == 'trialing'"
            style="margin-bottom: 10px; font-weight: 500; font-size: 16pt"
          >
            <div v-if="!trial_over">
              {{ $t("clinic_billing.your_free_trial_ends_on") }}
              {{ trial_end_view }}
            </div>
            <div v-else-if="trial_over && billing_info.billing_strategy == 'clinic'">
              {{ $t("clinic_billing.your_free_trial_ended_on") }}
              {{ trial_end_view }}.
            </div>
          </div>

          <div v-if="!billing_info.subscription_status" style="margin-bottom: 30px">
            <p>
              {{ $t("clinic_billing.please_provide_billing_information_before_your_trial_end") }}
            </p>
          </div>

          <div v-if="!trial_over">
            <p>
              {{ $t("clinic_billing.archive_any_inactive_users_before_your_trial_ends") }}
            </p>
          </div>

          <div v-if="billing_info.subscription_status == 'past_due'" style="margin-bottom: 10px; font-weight: 500">
            {{ $t("clinic_billing.your_account_is_past_due") }}
          </div>

          <div
            v-if="
              billing_info.subscription_status == 'incomplete' ||
              billing_info.subscription_status == 'incomplete_expired' ||
              billing_info.subscription_status == 'unpaid' ||
              billing_info.subscription_status == 'canceled'
            "
          >
            <div style="margin-bottom: 10px; font-weight: 500">
              {{ $t("clinic_billing.we_had_trouble_billing_your_credit_card") }}
            </div>
          </div>
        </v-sheet>
      </v-layout>

      <!-- Yearly or free -->
      <v-layout v-if="is_free || is_yearly" align-center justify-center>
        <v-sheet elevation="3" style="width: 1000px; padding: 50px; margin: 30px">
          <div v-if="is_free && !is_yearly" style="margin-bottom: 10px; font-weight: 500; font-size: 16pt">
            {{ $t("clinic_billing.your_account_is_free") }}
          </div>
          <div v-if="is_free && !is_yearly">
            <p>{{ $t("clinic_billing.free_description") }}</p>
          </div>

          <div v-if="is_yearly" style="margin-bottom: 10px; font-weight: 500; font-size: 16pt">
            {{ $t("clinic_billing.your_account_on_yearly") }}
          </div>
          <div v-if="is_yearly">
            <p>{{ $t("clinic_billing.yearly_description") }} {{ yearly_ends }}</p>
          </div>
        </v-sheet>
      </v-layout>

      <!-- Account Balance -->
      <v-layout v-if="billing_info && billing_info.amount_due" align-center justify-center>
        <v-sheet elevation="2" style="width: 1000px; padding: 50px; margin: 30px">
          <v-layout justify-space-between>
            <v-flex xs4 style="font-size: 16pt; font-weight: 450">{{ $t("clinic_billing.your_balance") }}</v-flex>
            <v-flex xs4 offset-xs1 style="font-size: 20pt; font-weight: 100; text-align: right">
              {{ amount_due_view }}
              <span v-if="billing_info.currency != 'usd'" style="text-transform: uppercase">{{ billing_info.currency }}</span>
            </v-flex>
          </v-layout>
          <v-layout style="margin-top: 50px; font-size: smaller">
            <v-flex xs3>
              <div v-if="billing_info.payment_preference == 'send_invoice'">
                <v-icon small left>fa-file-invoice-dollar</v-icon>
                {{ $t("clinic_billing.send_invoice") }}
              </div>
              <div v-else>
                <v-icon small left>credit_card</v-icon>
                {{ $t("clinic_billing.automatic_payments") }}
              </div>
            </v-flex>
            <v-flex v-if="billing_info.latest_invoice_url" xs2 offset-xs7 style="text-align: right">
              <v-btn color="primary" :href="billing_info.latest_invoice_url">{{ $t("clinic_billing.view_invoice") }}</v-btn>
            </v-flex>
          </v-layout>

          <!-- TOOD: enable preview of next invoice here
        <v-divider style="margin-top:50px; margin-bottom: 50px"></v-divider>

        <v-layout style="font-size: smaller">
          <v-flex xs5>
            <v-icon small left>supervisor_account</v-icon>
            You currently have {{ current_quantity }} users
          </v-flex>
          <v-flex xs5 offset-xs2>
            <v-icon small left>attach_money</v-icon>Estimated amount due Jan 1, 2020: &nbsp;$917.25 CAD
          </v-flex>
        </v-layout>
          -->
        </v-sheet>
      </v-layout>

      <!-- Payment Method Status -->
      <BillingPayment
        v-if="billing_info && !is_free"
        v-model="billing_info"
        context="clinic"
        :context_object="clinic"
        :stripe_public_key="stripe_public_key"
        :clinic_admins="clinic_admins"
        :user_subscription_price_amount="user_subscription_price_amount"
        :clinic_subscription_price_amount="current_price"
        :allow_change_billing_strategy="allow_change_billing_strategy"
        @billing_updated="billing_updated"
      />

      <!-- Invoices -->
      <v-layout v-if="invoices.length" align-center justify-center>
        <v-sheet elevation="2" style="width: 1000px; padding: 50px; margin: 30px">
          <v-layout justify-space-between>
            <v-flex xs4 style="font-size: 16pt; font-weight: 450">{{ $t("clinic_billing.invoices_and_payment_history") }}</v-flex>
          </v-layout>

          <div v-if="invoices.length">
            <table class="clinic_billing_invoices">
              <tr>
                <th></th>
                <th>{{ $t("clinic_billing.invoice_number") }}</th>
                <th>{{ $t("common.date") }}</th>
                <th>{{ $t("clinic_billing.amount") }}</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>

              <tr v-for="(invoice, index) in invoices" :key="index">
                <td>
                  <v-tooltip v-if="invoice.refund && invoice.total == invoice.refund" top>
                    <template v-slot:activator="{ on }">
                      <v-icon small v-on="on">fas fa-undo</v-icon>
                    </template>
                    <span>{{ $t("clinic_billing.invoice_refunded") }}</span>
                  </v-tooltip>
                  <v-tooltip v-else-if="invoice.status == 'paid'" top>
                    <template v-slot:activator="{ on }">
                      <v-icon small color="green" v-on="on">fas fa-check-circle</v-icon>
                    </template>
                    <span>
                      {{ $t("clinic_billing.invoice_paid") }}
                      <br />
                      {{ $t("clinic_billing.thank_you") }}!
                    </span>
                  </v-tooltip>

                  <v-tooltip v-else-if="invoice.status == 'void'" top>
                    <template v-slot:activator="{ on }">
                      <v-icon small v-on="on">fas fa-ban</v-icon>
                    </template>
                    <span>{{ $t("clinic_billing.invoice_voided") }}</span>
                  </v-tooltip>
                </td>
                <td>
                  <pre style="display: inline; user-select: all">{{ invoice.number }}</pre>
                </td>
                <td>{{ invoice_date(invoice) }}</td>
                <td>
                  {{ invoice_amount(invoice) }}
                  <span v-if="invoice.currency != 'usd'" style="text-transform: uppercase">{{ invoice.currency }}</span>
                </td>
                <td>
                  <a style="color: black; font-weight: 430; text-decoration: none" :href="invoice.invoice_pdf">
                    <v-icon small left>fas fa-file-invoice-dollar</v-icon>
                    {{ $t("clinic_billing.invoice") }}
                  </a>
                </td>

                <td>
                  <!-- After September 10 2021 -->
                  <template v-if="invoice.created > 1633849200 && invoice.total">
                    <v-icon small left @click="view_patient_log(invoice)">mdi-account-multiple</v-icon>
                    <span style="cursor: pointer" @click="view_patient_log(invoice)">{{ $t("common.patients") }}</span>
                  </template>
                </td>

                <td>
                  <a v-if="invoice.amount_remaining && invoice.status != 'void' && invoice.status != 'paid'" :href="invoice.hosted_invoice_url">{{
                    $t("clinic_billing.pay_now")
                  }}</a>

                  <span v-if="invoice.status == 'void'">{{ $t("clinic_billing.void") }}</span>

                  <a v-if="invoice.charge" :href="invoice_receipt_url(invoice)" target="_blank">
                    <v-icon small left>fas fa-receipt</v-icon>
                  </a>
                  <span v-else-if="invoice.status == 'paid' && invoice.total">
                    {{ $t("clinic_billing.paid_offline") }}
                  </span>

                  <a v-if="invoice.charge" :href="invoice_receipt_url(invoice)" target="_blank" style="color: black; font-weight: 430; text-decoration: none">{{
                    $t("clinic_billing.view_receipt")
                  }}</a>
                </td>
              </tr>
            </table>
          </div>

          <div v-else style="margin-top: 50px">
            {{ $t("clinic_billing.no_invoices") }}
          </div>
        </v-sheet>
      </v-layout>

      <!-- User Subscriptions -->
      <v-layout v-if="stripe_subscriptions.length" align-center justify-center>
        <v-sheet elevation="2" style="width: 1000px; padding: 50px; margin: 30px">
          <v-layout justify-space-between>
            <v-flex xs4 style="font-size: 16pt; font-weight: 450">{{ $t("clinic_billing.user_subscriptions") }}</v-flex>
          </v-layout>

          <div v-if="stripe_subscriptions.length">
            <p>{{ $t("clinic_billing.user_subscriptions_desc") }}</p>

            <table class="clinic_billing_invoices">
              <tr>
                <th></th>
                <th>{{ $t("clinic_billing.amount") }}</th>
                <th></th>
                <th></th>
                <th></th>
              </tr>

              <tr v-for="(subscription, index) in stripe_subscriptions" :key="index">
                <td>
                  {{ subscription.description }}
                </td>
                <td>
                  {{ format_price(subscription.plan.amount, subscription.plan.currency) }}
                  / {{ $t("common." + subscription.plan.interval) }}
                </td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </table>
          </div>

          <div v-else style="margin-top: 50px">
            {{ $t("clinic_billing.no_invoices") }}
          </div>
        </v-sheet>
      </v-layout>

      <!-- Pricing Info -->
      <v-layout align-center justify-space-around>
        <v-sheet elevation="2" style="padding: 50px; width: 1000px">
          <h2 v-if="!is_free && !trial_over" class="pt-6">
            {{ $t("clinic_billing.trial_period") }}
          </h2>

          <p v-if="!is_free && !trial_over">
            {{ $t("clinic_billing.trial_period_text") }}
          </p>

          <h2>{{ $t("clinic_billing.pricing_info") }}</h2>

          <!-- If we are on clinic-pays pricing, show this first -->
          <div v-if="!billing_info.billing_strategy || billing_info.billing_strategy == 'clinic'">
            <table style="font-weight: light; font-size: larger">
              <tr>
                <td>
                  {{ current_price }} /
                  {{ $t("clinic_billing.price_per_active_patient") }}
                </td>
              </tr>
            </table>

            <ul class="mt-3">
              <li>{{ $t("clinic_billing.patients_text") }}</li>
              <li>{{ $t("clinic_billing.doctor_staff_text") }}</li>
              <li>{{ $t("clinic_billing.billing_period_text") }}</li>
              <li>
                <i18n path="clinic_billing.final_text" tag="span">
                  <template v-slot:url>
                    <a href="https://help.ocudigital.com/article/41-billing" target="_blank">https://help.ocudigital.com/article/41-billing</a>
                  </template>
                </i18n>
              </li>
            </ul>

            <div v-if="!billing_info.billing_strategy || billing_info.billing_strategy == 'clinic'" class="mt-3">
              <h3>{{ $t("clinic_billing.user_pays_pricing") }}</h3>

              <div>
                <p style="font-weight: light; font-size: larger">
                  {{ user_subscription_price_amount }} /
                  {{ $t("common.month") }}
                </p>

                <p>{{ $t("clinic_billing.user_pays_pricing_text") }}</p>
              </div>
            </div>
          </div>

          <!-- If we are on user-pays pricing, show this first -->
          <div v-else>
            <div>
              <p style="font-weight: light; font-size: larger">
                {{ $t("clinic_billing.user_pays_inline") }}
                {{ user_subscription_price_amount }} /
                {{ $t("common.month") }}
              </p>

              <p>{{ $t("clinic_billing.user_pays_pricing_text_active") }}</p>
            </div>

            <h3>{{ $t("clinic_billing.clinic_pays_title") }}</h3>
            <p>
              {{ $t("clinic_billing.pricing_info_text_user") }}
            </p>
            <table style="font-weight: light">
              <tr>
                <td>
                  {{ current_price }} /
                  {{ $t("clinic_billing.price_per_active_patient") }}
                </td>
              </tr>
            </table>

            <ul class="mt-3">
              <li>{{ $t("clinic_billing.patients_text") }}</li>
              <li>{{ $t("clinic_billing.doctor_staff_text") }}</li>
              <li>{{ $t("clinic_billing.billing_period_text") }}</li>
              <li>
                <i18n path="clinic_billing.final_text" tag="span">
                  <template v-slot:url>
                    <a href="https://help.ocudigital.com/article/41-billing" target="_blank">https://help.ocudigital.com/article/41-billing</a>
                  </template>
                </i18n>
              </li>
            </ul>
          </div>

          <div v-if="bulk_price" class="mt-3">
            <h3>{{ $t("clinic_billing.bulk_pricing") }}</h3>

            <p style="font-weight: light; font-size: larger">{{ bulk_price }} / {{ $t("clinic_billing.price_per_year") }}</p>

            <p>{{ $t("clinic_billing.bulk_pricing_text") }}</p>
          </div>

          <div class="mt-3">
            <h3>{{ $t("clinic_billing.free_eductional_use") }}</h3>

            <p>{{ $t("clinic_billing.free_eductional_use_text") }}</p>

            <v-btn text color="primary accent-4" style="margin-left: -15px" @click="contact_us_dialog = true">{{ $t("common.contact_us") }}</v-btn>
            <v-dialog v-model="contact_us_dialog" max-width="600px">
              <ContactUs @close="contact_us_dialog = false" />
            </v-dialog>
          </div>
        </v-sheet>
      </v-layout>

      <!-- Referal Code -->
      <!-- <ReferralCodeInfo :clinic="clinic" />-->

      <v-layout v-if="tax_info" align-center justify-space-around class="mt-6">
        <v-sheet elevation="2" style="padding: 50px; width: 1000px">
          <div v-html="tax_info"></div>
        </v-sheet>
      </v-layout>
    </div>

    <!-- Loading -->
    <div v-else style="margin-top: 200px">
      <v-layout justify-center>
        <v-flex md1>
          <v-progress-circular indeterminate color="primary"></v-progress-circular>
        </v-flex>
      </v-layout>
    </div>

    <!-- Patient Log Modal -->
    <v-dialog v-model="patient_log_dialog" max-width="600px">
      <v-card v-if="patient_log_dialog">
        <v-card-title style="padding: 0px">
          <v-toolbar grey flat>
            <v-btn icon @click="patient_log_dialog = false">
              <v-icon>close</v-icon>
            </v-btn>
            <v-toolbar-title>
              {{ $t("clinic_billing.patient_billed") }}
              <!--
              <span
                v-if="!patient_log_loading"
              >: {{ $t("clinic_billing.num_total", {num: patient_log_list.length }) }}</span>
              -->
            </v-toolbar-title>
          </v-toolbar>
        </v-card-title>
        <v-divider />

        <div v-if="patient_log_loading">{{ $t("common.loading") }}</div>
        <div v-else>
          <table class="patient_log_list">
            <tr v-for="(patient, index) in patient_log_list" :key="index" class="pa-2">
              <td class="pa-2 pl-5">{{ patient.name[0] }}</td>
              <td class="pa-2 pl-5 pr-5">
                <span v-if="patient.first && patient.last">{{ $t("common.start") }} → {{ $t("common.end") }}</span>
                <span v-else-if="patient.first && !patient.last">{{ $t("common.start") }} → {{ patient.end }}</span>
                <span v-else-if="!patient.first && patient.last">{{ patient.start }} → {{ $t("common.end") }}</span>
                <span v-else>{{ patient.start }} → {{ patient.end }}</span>
              </td>
            </tr>
          </table>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import ContactUs from "./ContactUs";
import BillingPayment from "./BillingPayment";
import all_countries from "../../../data/all_countries_list.json";
import locales from "../../../data/locales.yml";

export default {
  components: { ContactUs, BillingPayment },
  props: {
    current_user_role: {
      type: String,
      required: true,
    },
    clinic: {
      type: Object,
      required: true,
    },
    clinic_admins: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      loading: true,
      contact_us_dialog: false,
      in_flight: false,
      error_message: "",
      locales: locales,
      patient_log_list: [],
      patient_log_dialog: false,
      patient_log_loading: false,
      stripe_public_key: "",

      // Stripe
      invoices: [],
      stripe_subscriptions: [],

      // Billing Info
      billing_info: null,

      // User subscription types
      user_subscription_types: [],
    };
  },

  computed: {
    stripe_live_mode() {
      return this.stripe_public_key.startsWith("pk_live");
    },
    allow_change_billing_strategy() {
      return true;
    },
    current_locale_country() {
      if (this.locales[this.billing_info.billing_country]) {
        return this.locales[this.billing_info.billing_country];
      }

      var country_name = this.billing_info.billing_country;
      for (var country of all_countries) {
        if (country.value == this.billing_info.billing_country) {
          country_name = country.text;
          break;
        }
      }

      return {
        code: this.billing_info.billing_country,
        name: country_name,
        postal_name: "Postal Code",
        currency: "USD",
        price: "$6.99 USD",
        stripe_user_coupon: "",
        stripe_price: {
          test: "price_1GygSoFpF80gxM91mPohhQRE",
          live: "price_1GygWYFpF80gxM91Tdz0Yfv6",
        },
        languages: {
          "en-US": {
            code: "en-US",
            name: "English",
          },
        },
      };
    },
    user_subscription_price_amount() {
      // TODO: Handle multiple subscription types

      if (this.user_subscription_types.length == 0) {
        return "";
      }
      let subscription_type = this.user_subscription_types[0];

      let stripe_prices = [];
      for (let i in subscription_type.stripe_prices) {
        stripe_prices.push(subscription_type.stripe_prices[i]);
      }

      if (stripe_prices.length == 0) {
        return "";
      }

      let price = stripe_prices[0];

      let price_amount = price.unit_amount;
      let currency = price.currency;

      // Apply coupon if there is one
      if (this.stripe_user_coupon) {
        if (
          !this.stripe_user_coupon.applies_to ||
          (this.stripe_live_mode && this.stripe_user_coupon.applies_to.products.includes(subscription_type.product_live)) ||
          (!this.stripe_live_mode && this.stripe_user_coupon.applies_to.products.includes(subscription_type.product_test))
        ) {
          if (this.stripe_user_coupon.percent_off) {
            price_amount = price_amount * (1 - this.stripe_user_coupon.percent_off / 100);
          }
          if (this.stripe_user_coupon.amount_off) {
            price_amount = price_amount - this.stripe_user_coupon.amount_off;
          }
        }
      }

      return this.format_price(price_amount, currency);
    },
    current_locale_district() {
      if (this.billing_district && this.current_locale_country && this.current_locale_country.districts[this.billing_district]) {
        return this.current_locale_country.districts[this.billing_district];
      }

      return null;
    },
    current_price() {
      let locale = this.current_locale_country;

      if (!locale) {
        return "";
      }

      return locale.price;
    },
    bulk_price() {
      let locale = this.current_locale_country;
      if (locale && locale.bulk_price) {
        return locale.bulk_price;
      }
      return "";
    },
    tax_info() {
      let tax_info = "";

      let locale = this.current_locale_country;

      if (locale && locale.invoice_footer) {
        tax_info += locale.invoice_footer + "<br/>";
      }

      let district = this.current_locale_district;
      if (district && district.invoice_footer) {
        tax_info += district.invoice_footer;
      }

      return tax_info;
    },
    amount_due_view() {
      if (this.billing_info) {
        return new Intl.NumberFormat(this.$i18n.lang(), {
          style: "currency",
          currency: this.billing_info.currency || "USD",
        }).format(this.billing_info.amount_due);
      } else {
        return "";
      }
    },
    amount_due_date_view() {
      if (this.billing_info.amount_due_date) {
        const options = {
          year: "numeric",
          month: "short",
          day: "numeric",
        };
        let due = new Date(this.billing_info.amount_due_date * 1000);
        return due.toLocaleDateString(this.$i18n.lang(), options);
      } else {
        return "";
      }
    },
    trial_end_view() {
      if (!this.clinic.trial_end) {
        return null;
      }

      var trial_end = new Date(this.clinic.trial_end);

      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      return trial_end.toLocaleDateString(this.$i18n.lang(), options);
    },
    is_free() {
      return this.clinic.has_tag("free");
    },
    is_yearly() {
      return this.clinic.has_tag("yearly_ends") || this.clinic.has_tag("yearly_billed_monthly_ends");
    },
    yearly_ends() {
      if (this.clinic.has_tag("yearly_ends")) {
        return this.clinic.tag_value("yearly_ends");
      } else if (this.clinic.has_tag("yearly_billed_monthly_ends")) {
        return this.clinic.tag_value("yearly_billed_monthly_ends");
      } else {
        return "";
      }
    },
    trial_over() {
      if (this.is_free || this.is_yearly) {
        return false;
      }
      if (!this.clinic.trial_end) {
        return false;
      }
      var trial_end = new Date(this.clinic.trial_end);
      var now = new Date();
      return trial_end < now;
    },
  },
  mounted() {
    this.load_billing();
    this.load_user_coupon();
  },
  methods: {
    load_user_coupon() {
      if (this.clinic.has_tag("user_subscription_coupon")) {
        let coupon_id = this.clinic.tag_value("user_subscription_coupon");
        axios
          .get("/api/stripe/coupon/" + coupon_id)
          .then((response) => {
            let resp = response.data;
            if (resp.status == "success") {
              this.stripe_user_coupon = resp.data;
            } else {
              messageBus.$emit("api-error", resp);
            }
          })
          .catch((error) => {
            messageBus.$emit("api-error", error);
          });
      }
    },
    format_price(amount_cents, currency) {
      var formatter = new Intl.NumberFormat(this.$i18n.lang(), {
        style: "currency",
        currency: currency,
      });

      let formatted_price = formatter.format(amount_cents / 100).toString();

      // Remove leading ISO codes and trailing .00
      if (/^[A-Z]{2}/.test(formatted_price)) {
        formatted_price = formatted_price.substring(2);
      }
      if (formatted_price.endsWith(".00")) {
        formatted_price = formatted_price.substring(0, formatted_price.length - 3);
      }

      if (currency != "usd" && formatted_price.startsWith("$")) {
        formatted_price = formatted_price + " " + currency.toUpperCase();
      }

      return formatted_price;
    },
    billing_updated() {
      this.$emit("clinic-updated");
      this.load_billing();
    },
    reset() {
      // TODO: Reset BillingPayment
      // call ref BillingPayment reset()
    },
    load_billing() {
      this.reset();
      this.load_user_subscription_types();

      axios.get("/api/clinic/" + this.clinic.id + "/billing").then((response) => {
        let resp = response.data;
        if (resp.status == "success") {
          this.billing_info = resp.data;
          this.stripe_public_key = resp.data.stripe_public_key;

          // Fix up some potentially missing info on billing_info we can reasonably derive it

          // Assign billing contact if it's missing
          if (!this.billing_info.billing_contact && this.clinic_admins.length > 0) {
            this.billing_info.billing_contact = this.clinic_admins[0].id;
          }

          // Determine billing contact and default name-on-card
          if (!this.billing_info.name_on_card && this.billing_info.billing_contact) {
            this.clinic_admins.forEach((admin) => {
              if (admin.id == this.billing_info.billing_contact) {
                this.billing_info.name_on_card = admin.name_given + " " + admin.name_family;
              }
            });
          }

          // Fix old 'ca' billing_country
          // TODO: Remove this when all clinics have uppercase country codes
          if (this.billing_info.billing_country) {
            this.billing_info.billing_country = this.billing_info.billing_country.toUpperCase();
          } else {
            this.billing_info.billing_country = "US";
          }

          // Default address if missing
          if (!this.billing_info.billing_address_1 && !this.billing_info.billing_address_2 && this.clinic.physical_address_1) {
            this.billing_info.billing_address_1 = this.clinic.physical_address_1;
            this.billing_info.billing_address_2 = this.clinic.physical_address_2;
            this.billing_info.billing_locality = this.clinic.physical_locality;
            this.billing_info.billing_district = this.district_map();
            this.billing_info.billing_postal = this.clinic.physical_postal;
          }
        } else {
          messageBus.$emit("api-error", resp);
        }

        // After basic billing info is loaded, load stuff below the fold
        this.loading = false;
        this.load_invoices();
        this.load_stripe_subscriptions();
      });
    },
    view_patient_log(invoice) {
      let start = new Date(invoice.period_start * 1000).toISOString().substring(0, 19);
      let end = new Date(invoice.period_end * 1000).toISOString().substring(0, 19);

      this.patient_log_loading = true;
      axios.get("/api/clinic/" + this.clinic.id + "/patient_log/" + start + "/" + end).then((response) => {
        let resp = response.data;
        if (resp.status == "success") {
          this.patient_log_list = this.map_patient_log(resp.data);
          this.patient_log_dialog = true;
          this.patient_log_loading = false;
        } else {
          messageBus.$emit("api-error", resp);
        }
      });
    },
    load_user_subscription_types() {
      axios
        .get("/api/subscription_types?include_stripe&strategy=user&country=" + this.clinic.billing_country)
        .then((response) => {
          let resp = response.data;
          if (resp.status == "success") {
            // Translate object to array
            let sub_types = [];
            for (var i in resp.data) {
              sub_types.push(resp.data[i]);
            }
            this.user_subscription_types = sub_types;
          } else {
            messageBus.$emit("api-error", resp);
          }
        })
        .catch((error) => {
          messageBus.$emit("api-error", error);
        });
    },
    load_invoices() {
      axios.get("/api/clinic/" + this.clinic.id + "/billing/invoices").then((response) => {
        let resp = response.data;
        if (resp.status == "success") {
          this.invoices = resp.data;
        } else {
          messageBus.$emit("api-error", resp);
        }
      });
    },
    load_stripe_subscriptions() {
      axios.get("/api/clinic/" + this.clinic.id + "/stripe_subscriptions?status=active").then((response) => {
        let resp = response.data;
        if (resp.status == "success") {
          this.stripe_subscriptions = resp.data;
        } else {
          messageBus.$emit("api-error", resp);
        }
      });
    },

    // Map physical district to default billing district
    district_map() {
      let district = this.clinic.physical_district.toLowerCase();

      if (!district || !this.locales) {
        return "";
      }
      let country = this.locales[this.clinic.billing_country];
      if (!country || !country.districts) {
        return "";
      }
      for (var i in country.districts) {
        let info = country.districts[i];
        let check_district = info.name.toLowerCase();
        if (district == info.code.toLowerCase() || district == check_district) {
          return info.code;
        }
      }
      return "";
    },
    invoice_date(invoice) {
      const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
      };
      let due = new Date(invoice.created * 1000);
      return due.toLocaleDateString(this.$i18n.lang(), options);
    },
    invoice_amount(invoice) {
      return new Intl.NumberFormat(this.$i18n.lang(), {
        style: "currency",
        currency: invoice.currency,
      }).format(invoice.total / 100);
    },
    invoice_receipt_url(invoice) {
      return "/api/clinic/" + this.clinic.id + "/billing/charge/" + invoice.charge + "/url";
    },

    // Map the patient log into a readable format by patient
    map_patient_log(logs) {
      let patients = {};

      for (var i in logs) {
        let log = logs[i];
        let first = i == 0;
        let last = i == logs.length - 1;
        for (let patient of log.data.patients) {
          if (!patients[patient.id]) {
            let new_patient = {
              first,
              last,
              earliest: log.created,
              latest: log.created,
              name: [patient.name],
              is_free: [patient.is_free],
              id: patient.id,
              owner_ids: [],
              owner_names: [],
            };

            if (patient.owner_id) {
              new_patient.owner_ids.push(patient.owner_id);
            }
            if (patient.owner_name) {
              new_patient.owner_names.push(patient.owner_name);
            }

            patients[patient.id] = new_patient;
          } else {
            let existing = patients[patient.id];

            if (first) {
              existing.first = true;
            }
            if (last) {
              existing.last = true;
            }

            existing.is_free.push(patient.is_free);
            existing.owner_ids.push(patient.owner_id);
            existing.name.push(patient.name);

            if (log.created < existing.earliest) {
              existing.earliest = log.created;
            }
            if (log.created > existing.latest) {
              existing.latest = log.created;
            }
          }
        }
      }

      let patient_list = [];
      for (const [_, patient] of Object.entries(patients)) {
        const unique = (value, index, self) => {
          return self.indexOf(value) === index;
        };

        // Collapse name, owner_id and is_free to unique values
        patient.is_free = patient.is_free.filter(unique);
        patient.name = patient.name.filter(unique);
        patient.owner_ids = patient.owner_ids.filter(unique);
        patient.owner_names = patient.owner_names.filter(unique);

        let start = new Date(Date.parse(patient.earliest + "Z"));
        patient.start = start.toLocaleDateString(this.$i18n.lang(), {
          month: "long",
          day: "numeric",
        });
        let end = new Date(Date.parse(patient.latest + "Z"));
        patient.end = end.toLocaleDateString(this.$i18n.lang(), {
          month: "long",
          day: "numeric",
        });

        patient_list.push(patient);
      }

      patient_list.sort((a, b) => {
        if (a.earliest == b.earliest) {
          return a.latest < b.latest ? -1 : a.latest > b.latest ? 1 : 0;
        } else {
          return a.earliest < b.earliest ? -1 : 1;
        }
      });
      return patient_list;
    },
  },
};
</script>

<style>
.clinic_billing .StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid transparent;
  border-radius: 4px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.clinic_billing .StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.clinic_billing .StripeElement--invalid {
  border-color: #fa755a;
}

.clinic_billing .StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.clinic_billing_invoices {
  width: 100%;
  margin-top: 50px;
}

.clinic_billing_invoices tr td {
  padding-top: 10px;
  padding-bottom: 10px;
}

.clinic_billing_invoices tr td {
  border-bottom: 1px solid gray !important;
}

.clinic_billing_invoices th {
  text-align: left;
  border-bottom: 1px solid gray !important;
}

table.patient_log_list,
.patient_log_list th,
.patient_log_list td {
  border: 1px solid grey;
  border-collapse: collapse;
}
</style>
